<template>

    <div class="add-outlet-form-container">

        <h1>Edit outlet</h1>

        <div class="text-red" v-if="error">{{ error }}</div>
        
        <form action="" @submit.prevent="updateOutlet">
            <div>
                <div v-for="language in languages" v-bind:key="language" >
                    <input class="input"
                        autofocus autocomplete="off"
                        name="outlet[name]"
                        :placeholder="languageLongForm(language) + ' name for item'"
                        v-model="newOutlet[language + '_name']" 
                        required />
                </div>

                <input class="input"
                    v-if="category_header == false"
                    autofocus autocomplete="off"
                    name="outlet[name]"
                    type="number"
                    placeholder="Add menu ID for this outlet"
                    v-model="newOutlet.menu_id" />      

                <p class="form-identifier">Specify type</p>
                <div class="toggle-button-with-selections">
                    <span class="toggle-button-text" :class="{ 'text-activated' : category_header == false }" @click="setToOrdinaryOutlet()">Outlet</span>
                    <span class="toggle-button-text" :class="{ 'text-activated' : category_header == true }" @click="setToCategoryHeader()">Category Header</span>
                </div>

            </div>
            <input type="submit" value="Update outlet" />
        </form>

    </div>

</template>


<script>

export default {
    name: 'EditOutlet',
    data () {
        return {
            user_type: '',
            name_before_edit: '',
            user_id: 0,
            error: '',
            admin_1: 0,
            admin_2: 0,
            admin_3: 0,
            category_header: false,
            languages: [],
            outlet_collection: {},
            newOutlet: {}
        }
    },
    created() {
        if (localStorage.signedIn) {
            this.$http.secured.get('/app_current_user')
                .then(response => { this.getOutlets(), this.user_type = response.data.user_type, this.user_id = response.data.user })
                .catch(error => this.setError(error, 'Something went wrong'))
        } else {
            this.$router.replace('/')
        }

        this.checkForAdmin();
        this.editOutlet();

    },
    methods: {
        getOutlets() {
            let custom_url = window.location.hostname.split('.').shift();
            this.$http.plain.get('/app_get_outlets/' + custom_url)
                .then(response => { 
                    this.admin_1 = response.data.outlet_collection.admin_1
                    this.admin_2 = response.data.outlet_collection.admin_2
                    this.admin_3 = response.data.outlet_collection.admin_3
                    this.outlet_collection = response.data.outlet_collection
                    this.languages = response.data.languages
                    })
                .catch(error => this.setError(error, 'Something went wrong'))            
        },
        editOutlet() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.get('/app_edit_outlet/' + id)
                .then(response => { 
                        this.newOutlet.en_name = response.data.outlet.en_name,
                        this.newOutlet.ru_name = response.data.outlet.ru_name,
                        this.newOutlet.jp_name = response.data.outlet.jp_name,
                        this.newOutlet.sp_name = response.data.outlet.sp_name,
                        this.newOutlet.fr_name = response.data.outlet.fr_name,
                        this.newOutlet.ca_name = response.data.outlet.ca_name,
                        this.newOutlet.kr_name = response.data.outlet.kr_name,
                        this.newOutlet.de_name = response.data.outlet.de_name, 
                        this.newOutlet.zh_name = response.data.outlet.zh_name,
                        this.newOutlet.nl_name = response.data.outlet.nl_name,
                        this.newOutlet.pt_name = response.data.outlet.pt_name,
                        this.newOutlet.it_name = response.data.outlet.it_name,
                        this.newOutlet.ar_name = response.data.outlet.ar_name,  
                        this.newOutlet.ina_name = response.data.outlet.ina_name,
                        this.newOutlet.menu_id = response.data.outlet.menu_id,
                        this.category_header = response.data.outlet.category_header
                    })
                .catch(error => this.setError(error, 'Something went wrong'))  
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        updateOutlet () {
            let id = this.$router.currentRoute.params.id;
            const value = this.newOutlet
            if (!value) {
                return
            }
            this.$http.secured.post('/app_update_outlet/' + id, { outlet: { en_name: this.newOutlet.en_name, 
                ru_name: this.newOutlet.ru_name, 
                jp_name: this.newOutlet.jp_name,
                sp_name: this.newOutlet.sp_name,
                fr_name: this.newOutlet.fr_name, 
                ca_name: this.newOutlet.ca_name, 
                kr_name: this.newOutlet.kr_name,
                de_name: this.newOutlet.de_name,  
                zh_name: this.newOutlet.zh_name, 
                nl_name: this.newOutlet.nl_name, 
                pt_name: this.newOutlet.pt_name,
                it_name: this.newOutlet.it_name,
                ar_name: this.newOutlet.ar_name,     
                ina_name: this.newOutlet.ina_name,                          
                menu_id: this.newOutlet.menu_id, user_id: this.user_id, outlet_collection_custom_url: window.location.hostname.split('.').shift(), category_header: this.category_header } })
                .then(response => {
                    console.log(response.data)
                    this.$router.replace("/" + this.languages[0])
                })
                .catch(error => this.setError(error, 'Cannot create outlet. Make sure the menu ID is valid.'))
        },
        setToOrdinaryOutlet() {
            this.category_header = false
        },
        setToCategoryHeader() {
            this.category_header = true
        },
        languageLongForm(language) {
            if (language == 'en') {
                return 'English'
            } else if (language == 'ru') {
                return 'Russian'
            } else if (language == 'jp') {
                return 'Japanese'
            } else if (language == 'sp') {
                return 'Spanish'
            } else if (language == 'fr') {
                return 'French'
            } else if (language == 'ca') {
                return 'Catalan'
            } else if (language == 'kr') {
                return 'Korean'
            } else if (language == 'de') {
                return 'German'
            } else if (language == 'zh') {
                return 'Chinese'
            } else if (language == 'nl') {
                return 'Dutch'
            } else if (language == 'pt') {
                return 'Portuguese'
            } else if (language == 'it') {
                return 'Italian'
            } else if (language == 'ar') {
                return 'Arabic'
            } else if (language == 'ina') {
                return 'Indonesian'
            }
        },
        canEdit() {
            if (this.user_type == 'admin' || this.user_id == this.outlet_collection.admin_1 || this.user_id == this.outlet_collection.admin_2 || this.user_id == this.outlet_collection.admin_3) {
                return true
            } else {
                this.$router.replace('/')
            }
        },
        checkForAdmin() {
            if (this.user_type == 'admin' || this.user_id == this.admin_1 || this.user_id == this.admin_2 || this.user_id == this.admin_3) {
                console.log('authenticated')
            } else {
                console.log('not authenticated')
                this.$router.replace('/');
            }
        }
    }
}


</script>

<style scoped>

.add-outlet-form-container {
    max-width: 600px;
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 20px 0px 20px 0px;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 20px;
    font-weight: 600;
}

input[type="submit"] {
    background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 40px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 50px;
}

.toggle-button-with-selections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.toggle-button-with-selections span {
    /* border: 1px solid lightgray; */
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    border-radius: 5px;
    cursor: pointer;
    width: 49%;
}

.toggle-button-text {
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    height: 40px;
}

.toggle-button-text:hover {
    background-color: lightgray;
    color: white;
    transition: 0.2s;
}

.text-activated {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;
}

.text-activated:hover {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;    
}

.form-identifier {
    font-weight: 600;
    margin: 20px 0px 10px 0px;
    font-size: 12.5px;
}

.text-red {
    color: red;
    font-size: 12.5px;
}

</style>